import 'flowbite';
import '../../shared/js/shared.js'
import 'cookies.js'


document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.brand-item') !== null) {
        const links = document.querySelectorAll('.brand-item a.reveal-commissions');

        links.forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault(); // Voorkom de standaardactie van de link

                const brandItem = link.closest('.brand-item');

                if (brandItem) {
                    const listItems = brandItem.querySelectorAll('ul.commission-flow li');
                    listItems.forEach(function (listItem) {
                        listItem.classList.remove('hidden');
                    });

                    link.remove();
                }
            });
        });
    }
});